import {createContext, useEffect, useState} from 'react';
import {ApiClient} from '../Utils/ApiClient';
import {useContext} from 'react';
import {UserContext} from './userContext';
import * as d3 from 'd3';
const OverviewMapContext = createContext();
const throttledQueue = require('throttled-queue');

const throttle = throttledQueue(1, 1000);
const annotationsThrottle = throttledQueue(30, 1000);
const defaultSnapshotFilters = {
  status: 'all',
  maxSystemSpeed: 60,
  timeOfDay: null,
  isSpraying: 'all',
};

const defaultAnnotationFilters = {
  label: 'all',
  minConfidenceLevel: 0.50,
};

// calculates the heatmap color of the cell based on the ratio of the label count to the total plant count
const mapCellColorGradient = (startColor, endColor, labelCount, totalPlantCount) => {
  const colorScale = d3.scaleLinear()
      .domain([0, 1])
      .range([startColor, endColor]);

  const ratio = labelCount / totalPlantCount;
  return colorScale(ratio);
};

const OverviewMapProvider = ({userConfig, children}) => {
  const [greenhouseStats, setGreenhouseStats] = useState(null);
  const [annotationLabels, setAnnotationLabels] = useState(null);
  const [snapshotLabels, setSnapshotLabels] = useState(null);
  const {currentClient} = useContext(UserContext);
  const [startingDate, setStartingDate] = useState(new Date());
  const [endingDate, setEndingDate] = useState(null);
  const [snapshots, setSnapshots] = useState({});
  const [filteredSnapshots, setFilteredSnapshots] = useState({});
  const [lanes, setLanes] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [mapType, setMapType] = useState('snapshots');
  const [snapshotFilters, setSnapshotFilters] = useState(defaultSnapshotFilters);
  const [annotationFilters, setAnnotationFilters] = useState(defaultAnnotationFilters);
  const [showLaneBatchInfo, setShowLaneBatchInfo] =useState(false);
  const [snapshotImages, setSnapshotImages] = useState({});
  const [currentSnapshotImage, setCurrentSnapshotImage] = useState(null);
  const [showExpertAnnotations, setShowExpertAnnotations] = useState(false);
  const [expertAnnotations, setExpertAnnotations] = useState(null);
  const [expertAnnotationImages, setExpertAnnotationImages] = useState(null);
  const [laneBatchInfoFound, setLaneBatchInfoFound] = useState(false);

  useEffect(() => {
    if (currentClient && greenhouseStats) {
      setIsDataFetched(false);
      setShowLaneBatchInfo(false);
      setShowExpertAnnotations(false);
      setExpertAnnotations(null);
      setExpertAnnotationImages(null);
      setSnapshots({});
      setSnapshotFilters(defaultSnapshotFilters);
      setAnnotationFilters(defaultAnnotationFilters);
      setFilteredSnapshots({});
      setLanes(generateLaneData(greenhouseStats.lanes, greenhouseStats.sublanes, greenhouseStats.hooks));
      fetchOverviewMapData().then(() => {
        setIsDataFetched(true);
      });
    };
  }, [currentClient, greenhouseStats, startingDate, endingDate]);

  useEffect(() => {
    if (currentClient && greenhouseStats && Object.keys(filteredSnapshots).length > 0) {
      setLanes(generateLaneData(greenhouseStats.lanes, greenhouseStats.sublanes, greenhouseStats.hooks));
    }
  }, [filteredSnapshots]);

  useEffect(() => {
    if (userConfig) {
      setGreenhouseStats(userConfig.greenhouse_stats);
      setAnnotationLabels(userConfig.annotation_labels);
      setSnapshotLabels(userConfig.snapshot_labels);
    }
  }, [userConfig]);

  useEffect(() => {
    if (showLaneBatchInfo) {
      getLaneBatchInfo().then((newLanes) => {
        setLaneBatchInfoFound(true);
        setLanes(newLanes);
      });
    } else {
      if (currentClient && greenhouseStats) {
        setLanes(generateLaneData(greenhouseStats.lanes, greenhouseStats.sublanes, greenhouseStats.hooks));
      }
    }
  }, [showLaneBatchInfo]);

  useEffect(() => {
    filterLaneSnapshots();
  }, [snapshotFilters]);

  useEffect(() => {
    if (showExpertAnnotations && !expertAnnotations) {
      getSnapshotsAnnotations();
    }
  }, [showExpertAnnotations]);


  // downloads the high resolution image of the snapshot only when the plant list is expanded
  const getCurrentSnapshotImage = async (snapshot) => {
    const response = await ApiClient.downloadImage(currentClient, snapshot.data?.images?.rawHD);
    setCurrentSnapshotImage(response);
  };


  // downloads the low resolution images of all the snapshots in the current cell/section
  const getSnapshotImages = async (snapshots) => {
    const snapshotImages = {};
    await Promise.all(snapshots.map(async (snapshot) => {
      const response =
      await ApiClient.downloadImage(currentClient, snapshot.data?.images?.rawSD || snapshot.data?.images?.rawHD);
      snapshotImages[snapshot._id] = response;
    }));
    setSnapshotImages((prev) => ({...prev, ...snapshotImages}));
  };

  const getSnapshotsAnnotations = async () => {
    const combinedSnapshotsList = Object.values(filteredSnapshots).flat();
    const snapshotAnnotations = {};
    await Promise.all(combinedSnapshotsList.map(async (snapshot) => {
      annotationsThrottle();
      const response = await ApiClient.findAnnotations(snapshot.client, {snapshot_id: snapshot._id});
      if (response.length > 0) {
        snapshotAnnotations[snapshot._id] = response;
      };
    }));
    setExpertAnnotations(snapshotAnnotations);
  };

  const fetchExpertAnnotationImages = async (annotations) => {
    const annotationImages = {};
    await Promise.all(annotations.map(async (annotation) => {
      const response = await ApiClient.downloadImage(annotation.client, annotation.image_location);
      annotationImages[annotation._id] = response;
    }));
    setExpertAnnotationImages(annotationImages);
  };

  // calculates the color of the cell based on the status with the most number of snapshots
  const getCellColor = (snapshotList) => {
    if (snapshotList.length === 0) {
      return null;
    }
    const analyzedCount = snapshotList.filter((snapshot) => snapshot.status === 'analyzed').length;
    const failedCount = snapshotList.filter((snapshot) => snapshot.status === 'failed').length;
    const discardedCount = snapshotList.filter((snapshot) => snapshot.status === 'discarded').length;
    const incompleteCount = snapshotList.filter((snapshot) => snapshot.status === 'incomplete').length;
    const createdCount = snapshotList.filter((snapshot) => snapshot.status === 'created').length;
    const cellData = {color: null, heighestStatus: null};
    if (failedCount > 0) {
      cellData.color = snapshotLabels['Failed'].color;
      cellData.heighestStatus = 'failed';
    } else if (discardedCount > 0) {
      cellData.color = snapshotLabels['Discarded'].color;
      cellData.heighestStatus = 'discarded';
    } else if (incompleteCount > 0) {
      cellData.color = snapshotLabels['Incomplete'].color;
      cellData.heighestStatus = 'incomplete';
    } else if (createdCount > 0) {
      cellData.color = snapshotLabels['Created'].color;
      cellData.heighestStatus = 'created';
    } else if (analyzedCount > 0) {
      cellData.color = snapshotLabels['Analyzed'].color;
      cellData.heighestStatus = 'analyzed';
    }
    return cellData;
  };

  // filters the snapshots based on the filters
  const filterLaneSnapshots = () => {
    const filtered = {};
    // eslint-disable-next-line guard-for-in
    for (const lane in snapshots) {
      const laneSnapshots = snapshots[lane];
      if (laneSnapshots.length === 0) {
        filtered[lane] = [];
        continue;
      }
      const filteredLaneSnapshots = laneSnapshots.filter((snapshot) => {
        if (snapshotFilters.status !== 'all' && snapshot.status !== snapshotFilters.status) {
          return false;
        }
        if (snapshotFilters.maxSystemSpeed !== 'all' &&
        snapshot.data?.position_id?.speed * 60 > snapshotFilters.maxSystemSpeed) {
          return false;
        }
        if (snapshotFilters.timeOfDay !== null) {
          const snapshotTime = new Date(snapshot.collection_date);
          const filterTime = new Date(snapshotTime);
          filterTime.setHours(new Date(snapshotFilters.timeOfDay).getHours(),
              new Date(snapshotFilters.timeOfDay).getMinutes());
          if (snapshotTime < filterTime) {
            return false;
          }
        }
        let sprayStatus = snapshotFilters.isSpraying;
        if (sprayStatus === 'all') {
          sprayStatus = null;
        } else if (sprayStatus === 'Spraying') {
          sprayStatus = true;
        } else if (sprayStatus === 'Not Spraying') {
          sprayStatus = false;
        }
        if (sprayStatus &&
        snapshot.data?.spray_status?.is_spraying !== sprayStatus) {
          return false;
        }
        return true;
      });
      filtered[lane] = filteredLaneSnapshots;
    }
    setFilteredSnapshots(filtered);
  };

  // get the snapshots for a specific cell
  const getCellSnapshots = (laneSnapshots, lane, sublane, hook) => {
    if (!laneSnapshots || laneSnapshots.length === 0) return [];
    return laneSnapshots.filter((snapshot) => {
      // eslint-disable-next-line max-len
      return snapshot.data.lane === lane.toString() && snapshot.data.sub_lane === sublane && snapshot.data.position_id.hook === hook;
    });
  };


  // get the color of the annotation based on the most common label
  const getCellAnnotationColor = (cellSnapshots) => {
    if (cellSnapshots.length === 0) {
      return null;
    }
    const allPlants = [];
    cellSnapshots.forEach((snapshot) => {
      if (!snapshot.data?.analysis_data?.plant_record_list) return;
      allPlants.push(...snapshot.data?.analysis_data?.plant_record_list);
    });
    if (allPlants.length === 0) {
      return null;
    }
    const allLabels = [];
    allPlants.forEach((plant) => {
      if (!plant.disease_classifications || plant.disease_classifications.length === 0) return;
      allLabels.push(plant.disease_classifications[0].label);
    });
    if (allLabels.length === 0) {
      return null;
    }
    const uniqueLabels = [...new Set(allLabels)];
    const labelCounts = {};
    uniqueLabels.forEach((label) => {
      const labelCount = allLabels.filter((l) => l === label).length;
      labelCounts[label] = {label: label, count: labelCount, color: null};
      const colorLabels = Object.keys(annotationLabels);
      let lightestColor;
      let darkestColor;
      // eslint-disable-next-line max-len
      for (const colorLabel of colorLabels) {
        if (annotationLabels[colorLabel].value === label) {
          lightestColor = annotationLabels[colorLabel].heatmapColors.light;
          darkestColor = annotationLabels[colorLabel].heatmapColors.darkest;
        }
      }
      labelCounts[label].color = mapCellColorGradient(lightestColor, darkestColor, labelCount, allPlants.length);
    });
    const maxLabel = Object.keys(labelCounts).reduce((a, b) => labelCounts[a] > labelCounts[b] ? a : b);
    const labels = Object.keys(annotationLabels);
    const colors = {};
    for (const label of labels) {
      colors[annotationLabels[label].value] = annotationLabels[label].color;
    }

    // return the color and the highest label
    return {color: colors[maxLabel], heighestLabel: maxLabel, labelCounts: labelCounts, plantCount: allPlants.length};
  };

  // generate the data for the lanes cells
  const generateLaneData = (laneCount, sublanes, hookCount) => {
    const nCol = hookCount;
    const nRow = sublanes.length;

    const getLaneCellsSnapshots = (lane) => {
      const cellData = [];
      const laneSnapshots = filteredSnapshots[lane];
      for (let x = 0; x < nCol; x++) {
        for (let y = 0; y < nRow; y++) {
          const cellSnapshots = getCellSnapshots(laneSnapshots, lane, sublanes[y], x+1);
          const cellColorData = getCellColor(cellSnapshots);
          const cellColor = cellColorData?.color;
          const cellHighestStatus = cellColorData?.heighestStatus;
          const annotationValue = getCellAnnotationColor(cellSnapshots);
          const color = annotationValue ? annotationValue?.color : null;
          const heighestLabel = annotationValue ? annotationValue.heighestLabel : null;
          const labelCounts = annotationValue ? annotationValue.labelCounts : null;
          const plantCount = annotationValue ? annotationValue.plantCount : null;
          cellData.push({
            x: `${x+1}`,
            y: sublanes[y].toUpperCase(),
            value: Math.random() * 40,
            snapshots: cellSnapshots,
            color: cellColor,
            annotationColor: color,
            heighestLabel: heighestLabel,
            heighestStatus: cellHighestStatus,
            labelCounts: labelCounts,
            plantCount: plantCount,
          });
        }
      }
      return cellData;
    };

    // add data to all lanes
    const lanes = [];
    for (let i = 0; i < laneCount; i++) {
      if (!filteredSnapshots[i + 1] || filteredSnapshots[i + 1].length !== 0) {
        lanes.push({laneId: i + 1, cells: getLaneCellsSnapshots(i+1)});
      }
      if (!filteredSnapshots[i + 1]) {
        break;
      }
    }
    return lanes;
  };

  // fetch the snapshots for all lanes (lane by lane) for the current client and period
  const fetchOverviewMapData = async () => {
    const lanes = greenhouseStats.lanes; // number of lanes
    const laneData = [];
    for (let i = 1; i <= lanes; i++) {
      laneData.push(i);
    }
    await Promise.all(laneData.map(async (lane) => {
      throttle();
      const startDate = new Date(startingDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = endingDate ? new Date(endingDate) : new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
      // eslint-disable-next-line max-len
      const query = {'data.lane': lane.toString()};
      const response = await ApiClient.findSnapshotsByClient(startDate, endDate, null, query, true, currentClient);
      setSnapshots((prev) => ({...prev, [lane]: response}));
      setFilteredSnapshots((prev) => ({...prev, [lane]: response}));
    }));
  };

  // fetch the batch information for all lanes in the current map
  const getLaneBatchInfo = async () => {
    const lanesCopy = JSON.parse(JSON.stringify(lanes));
    await Promise.all(lanesCopy.map(async (lane) => {
      throttle();
      const laneSnapshots = snapshots[lane.laneId];
      if (laneSnapshots.length === 0) {
        return;
      }
      const oneLaneSnapshot = laneSnapshots[0];
      let batchInfoFound = true;
      const response = await ApiClient.getLaneInformationForSnapshot(oneLaneSnapshot);
      if (response.length === 0) {
        batchInfoFound = false;
      };
      const laneIndex = lanesCopy.findIndex((l) => l.laneId === lane.laneId);
      lanesCopy[laneIndex].laneBatchInfo = batchInfoFound;
    }));
    return lanesCopy;
  };


  return (
    // eslint-disable-next-line max-len
    <OverviewMapContext.Provider value={{greenhouseStats, setGreenhouseStats, snapshotLabels,
      annotationLabels, isDataFetched, lanes, getCellColor, currentClient, snapshotImages, getSnapshotImages,
      startingDate, setStartingDate, endingDate, setEndingDate, snapshotFilters, setSnapshotFilters,
      annotationFilters, setAnnotationFilters, mapType, setMapType, showLaneBatchInfo, setShowLaneBatchInfo,
      currentSnapshotImage, setCurrentSnapshotImage, getCurrentSnapshotImage,
      showExpertAnnotations, setShowExpertAnnotations, expertAnnotations, fetchExpertAnnotationImages,
      expertAnnotationImages, setExpertAnnotationImages, laneBatchInfoFound, setLaneBatchInfoFound}}>
      {children}
    </OverviewMapContext.Provider>
  );
};

export {OverviewMapProvider, OverviewMapContext};
