// CartContext.js
import React, {createContext, useState, useContext} from 'react';
import {ApiClient} from '../../Utils/ApiClient';
import {useEffect} from 'react';
import {UserContext} from '../../contexts/userContext';

const CartContext = createContext();

const CartProvider = ({children}) => {
  const [cartItems, setCartItems] = useState([]);
  const [showAlreadyInCartDialog, setShowAlreadyInCartDialog] = useState(false);
  const [hideCart, setHideCart] = useState(true);
  const {user, getUserConfig} = useContext(UserContext);

  useEffect(() => {
    const numberOfOperations = getUserConfig()?.shopping_cart?.operations?.length;
    if (numberOfOperations === 0 || numberOfOperations === undefined) {
      setHideCart(true);
    } else {
      setHideCart(false);
    }
  }, [user, getUserConfig()]);

  const closeDialog = () => {
    setShowAlreadyInCartDialog(false);
  };

  const addToCart = (newItem, overrideDuplicate = false) => {
    if (overrideDuplicate) {
      setCartItems((prevItems) =>
        prevItems.map((item) => (item._id === newItem._id ? newItem : item)),
      );
      return true;
    } else {
      if (isAlreadyInCart(newItem._id)) {
        setShowAlreadyInCartDialog(true);
        return false;
      }
    }
    setCartItems((prevItems) => [...prevItems, newItem]);
    return true;
  };

  const removeFromCart = (itemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item._id !== itemId));
  };

  const removeAllFromCart = () => {
    setCartItems([]);
  };

  const isAlreadyInCart = (itemId) => {
    return cartItems.some((item) => item._id === itemId);
  };

  const areAlreadyInCart = (itemIds) => {
    return itemIds.every((itemId) => isAlreadyInCart(itemId));
  };

  const reloadCartItems = () => {
    const cartItemsOld = [...cartItems];
    cartItemsOld.forEach((item) => {
      ApiClient.findSnapshotById(item._id).then((snapshot) => {
        addToCart(snapshot, true);
      });
    });
  };

  const isCartVisible = () => {
    return !hideCart;
  };

  return (
    <CartContext.Provider value={{
      cartItems, showAlreadyInCartDialog,
      closeDialog, addToCart, removeFromCart,
      removeAllFromCart, isAlreadyInCart, reloadCartItems,
      areAlreadyInCart, isCartVisible,
    }}>
      {children}
    </CartContext.Provider>
  );
};

export {CartContext, CartProvider};
